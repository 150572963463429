<template>
  <div class="join center">
    <div class="baseBox">
      <div class="joinBox">
        <h2>비밀번호 찾기</h2>
        <div class="form">
          <div class="flex">
            <p>이메일 <span>*</span></p>
            <input type="text" v-model="email" />
          </div>
          <div class="flex">
            <p>
              휴대전화
              <span>*</span>
            </p>
            <div class="cert">
              <input type="text" v-model="mobileNo" @keyup="removeChar" />
              <button class="point" @click="requestCertNumber">인증번호 받기</button>
            </div>
          </div>
          <div class="flex" v-show="isRequestCertNum">
            <p>
              인증번호
              <span>*</span>
            </p>
            <div class="cert">
              <input class="tel" type="tel" v-model="certNumber" @keyup="removeChar" placeholder="인증번호를 입력해주세요." />
              <button class="pointOut" @click="verifyNumber">인증번호 확인</button>
            </div>
          </div>
          <!-- <div class="flex">
            <p>이름</p>
            <input type="text" v-model="username" />
          </div>
          <div class="flex">
            <p>핸드폰번호</p>
            <input
              type="text"
              v-model="mobileNo"
              placeholder="숫자만 입력해주세요."
            />
          </div> -->
        </div>
        <div v-if="error" class="error">해당 정보로 가입한 회원정보를 찾을 수 없습니다.</div>
        <div class="buttonWrap">
          <button class="point large" @click="submit">비밀번호 찾기</button>
          <router-link to="/login">
            <button class="cancel large pointOut">취소</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { format } from "@/mixins/format";
import { findAccountPw, verifyMobile, createCertNumber } from "@/api/index";
export default {
  mixins: [format],
  data() {
    return {
      mobileNo: "",
      username: "",
      email: "",
      isRequestCertNum: false,
      certNumber: "",
      error: false,
    };
  },

  methods: {
    removeChar(event) {
      if (event.keyCode == 8 || event.keyCode == 46 || event.keyCode == 37 || event.keyCode == 39) return;
      else event.target.value = event.target.value.replace(/[^0-9]/g, "");
    },
    requestCertNumber() {
      if (this.mobileNo == "") {
        return alert("휴대폰번호를 입력해 주세요.");
      } else if (this.mobileNumberCheck(this.mobileNo) == false) {
        return alert("휴대폰번호 형식이 맞지 않습니다.");
      }
      let data = {
        mobileNo: this.mobileNo,
      };
      this.isRequestCertNum = true;
      createCertNumber(data).then((res) => {
        if (res.data.status == 200) {
          return alert("모바일을 통해서 인증번호를 확인 후 인증번호를 입력해주세요.");
        } else {
          alert(res.data.message);
        }
      });
    },
    verifyNumber() {
      if (this.certNumber == "") {
        return alert("인증 번호를 입력해 주세요.");
      }
      let data = {
        mobileNo: this.mobileNo,
        certNumber: this.certNumber,
      };
      verifyMobile(data).then((res) => {
        if (res.data.status == 200) {
          this.isVerify = true;
          return alert("정상적으로 인증이 완료 되었습니다.");
        } else if (res.data.status == 401) {
          this.isVerify = false;
          return alert(res.data.message);
        } else if (res.data.status == 407) {
          this.isVerify = false;
          return alert(res.data.message);
        } else if (res.data.status == 409) {
          this.isVerify = false;
          return alert(res.data.message);
        }
      });
    },
    submit() {
      if (this.email == "") {
        return alert("이메일을 입력해주세요.");
      } else if (this.mobileNo == "") {
        return alert("휴대폰번호를 입력해 주세요.");
      } else if (this.verify == false) {
        return alert("휴대폰 인증을 해주세요.");
      }
      let data = {
        email: this.email,
        mobileNo: this.mobileNo,
      };
      findAccountPw(data).then((res) => {
        if (res.data.status == 200) {
          this.$store.dispatch("SET_USER_INFO", data);
          this.$router.push("/changePassword");
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
  },
};
</script>
